import React from "react"
import PropTypes from "prop-types"
import { greyLighter, containerFixed } from "../../constants/cssVariables"
import { slopeRight } from "../../constants/classVariables"
import "./Section.css"

const Section = props => {
    const { children, bgColor, bgImage, container, cssClass, slopeSide } = props
    const customCssClass = cssClass ? cssClass : ""

    return (
        <section
            style={{
                backgroundColor: bgColor,
                backgroundImage: bgImage ? `url(${bgImage})` : "",
                maxWidth: container,
            }}
            className={`Section ${customCssClass} ${slopeSide}`}
        >
            {children}
        </section>
    )
}

Section.propTypes = {
    bgColor: PropTypes.string,
    container: PropTypes.string,
    slopeSide: PropTypes.string,
}

Section.defaultProps = {
    bgColor: greyLighter,
    container: containerFixed,
    slopeSide: slopeRight,
}

export default Section
